@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-gray-900;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    div {
        @apply text-2xl;
        @apply text-gray-100;
    }
}
